import React from "react"

import { Container } from "react-bootstrap"
import Layout from "components/layout"
import SEO from "components/seo"
import ResultsViewer from "components/ResultsViewer/ResultsViewer"

const ResultsPage = ({ location }) => {
  return (
    <div className="background-grey">
      <Layout pageInfo={{ pageName: "results" }}>
        <SEO title="Results" keywords={[`gatsby`, `react`, `bootstrap`]} />
        <Container fluid className="px-0">
          <ResultsViewer
            blobInfo={location.state ? location.state.blobInfo : null}
          />
        </Container>
      </Layout>
    </div>
  )
}

export default ResultsPage

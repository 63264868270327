import React from "react"
import { Row, Container, Toast } from "react-bootstrap"
import { useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfo } from "@fortawesome/free-solid-svg-icons"
import ResultCard from "components/ResultCard/ResultCard"

const ResultsViewer = ({ blobInfo }: ResultsViewerProps) => {
  const [results, setResults] = useState<AzureBlobInfo[]>([])
  const [noFilesWarning, setNoFilesWarning] = useState(false)

  const toastTimer = 3000

  useEffect(() => {
    const checkForResults = () => {
      //if no files are passed through the router, warn user.
      if (!blobInfo || blobInfo.length === 0) {
        setNoFilesWarning(true)
        return
      }
      setResults(blobInfo)
    }
    checkForResults()
  }, [blobInfo])

  return (
    <Container fluid className="px-0 my-5">
      <Row className="justify-content-around mx-0">
            {results.map((file, index) => {
              return (
                <ResultCard key={index} rowData={file} index={index}/>
              )
            })}
        <div className={"toast-container"}>
          <Toast
            show={noFilesWarning}
            onClose={() => {
              setNoFilesWarning(false)
            }}
            delay={toastTimer}
            autohide
          >
            <Toast.Header>
              <FontAwesomeIcon icon={faInfo} className={"sort-it-colour"} />
              <strong className="mr-auto ml-3">No Results</strong>
            </Toast.Header>
            <Toast.Body>No results for the selected files.</Toast.Body>
          </Toast>
        </div>
      </Row>
    </Container>
  )
}

export default ResultsViewer
